import React from "react";
import { CSSTransition } from "react-transition-group";
import "../assets/scss/components/StatusMessage.scss";

const StatusMessage = ({ statusMessage, hideAfterTimeout, timeout = 5000 }) => {
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={statusMessage !== null}
      timeout={timeout}
      classNames="status-message"
    >
      <div
        ref={nodeRef}
        className={`status-message mb-1_25 ${hideAfterTimeout || "no-hide"}`}
      >
        {statusMessage}
      </div>
    </CSSTransition>
  );
};

export default StatusMessage;
